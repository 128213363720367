<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-04 10:40:45
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 23:16:05
-->
<template>
  <div>
    <div class="list" v-if="list.length">
      <div class="item" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
        <!-- <div class="title"><img src="@/assets/images/VIP.png" /> 北京市2023年普通高等学校艺术类专业招生办法发布</div> -->
        <div class="title" v-if="item.post_title"><img src="@/assets/images/VIP.png" v-if="item.is_vip == 1" />{{ item.post_title }}</div>
        <div class="title" v-if="item.contents" v-html="item.contents"></div>
        <div class="time" v-if="item.published_time"><img src="@/assets/images/time.png" />{{ item.published_time }}</div>
      </div>
    </div>
    <div v-else class="noMsg">暂无内容</div>

    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">VIP用户才可以查看哦</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    active: {
      type: [Array, String]
    },
    type: String
  },
  data() {
    return {
      dialogTableVisible: false,
    };
  },
  methods: {
    goDetail(item) {
      var userInfo = this.$store.state.userInfo;
      if(this.active == 2){
        if (!this.$store.getters.uniqid) {
          this.$root.Bus.$emit('showLogin')
          return
        }
        this.$router.push('/question/' + item.id)
      }else{
        if(item.is_vip == 1){
          if (!this.$store.getters.uniqid) {
            this.$root.Bus.$emit('showLogin')
            return
          }else{
            if(userInfo.is_vip == 0){
              this.dialogTableVisible = true;
              return;
            }else{
              // if(this.type == 'down'){
              //   window.open('#/advisory', '_blank');
              //   return;
              // }
              window.open('#/newDetail?id='+item.id, '_blank');
            }
          }
        }else{
          // if(this.type == 'down'){
          //   window.open('#/advisory', '_blank');
          //   return;
          // }
          window.open('#/newDetail?id='+item.id, '_blank');
        }
      }
      // this.$router.resolve({
      //   name: 'newDetail',
      //   query: {
      //     id: item.id
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.noMsg{
  font-size: 16px;
  text-align: center;
  padding-top: 150px;
}
.list {
  padding: 0 20px;
  .item {
    font-size: 16px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #E8E8E8;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid #E8E8E8;
    }

    .title {
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;

      img {
        height: 20px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .time {
      img {
        height: 14px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
  }
}
</style>