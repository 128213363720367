<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 20:19:12
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-04 15:42:06
-->
<template>
  <div class="bd">
    <div class="act flex2">
      <div class="linkTitle"><img src="@/assets/images/link.png" alt="">友情链接</div>
    </div>
    <div class="act">
      <el-dropdown trigger="click" placement="top-start">
        <div class="ntion ">
          <span>初试常用网站</span>
          <i class="el-icon-caret-top el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown" :style="`width: ${inputWidth}px`">
          <el-dropdown-item v-for="(item, index) in link1" :key="index">
            <a :href="item.url" target="_blank">{{item.name}}</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="act">
      <el-dropdown trigger="click" placement="top-start">
        <div class="ntion">
          <span>复试常用网站</span>
          <i class="el-icon-caret-top el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown" :style="`width: ${inputWidth}px`">
          <el-dropdown-item v-for="(item, index) in link2" :key="index">
            <a :href="item.url" target="_blank">{{item.name}}</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="act">
      <el-dropdown trigger="click" placement="top-start">
        <div class="ntion" ref="itemContent" v-resize="resetTableHeight">
          <span>学习网站</span>
          <i class="el-icon-caret-top el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown" :style="`width: ${inputWidth}px`">
          <el-dropdown-item v-for="(item, index) in link3" :key="index">
            <a :href="item.url" target="_blank">{{item.name}}</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import resize from "../util/myresize.js";
export default{
  directives: {
    resize
  },
  data() {
    return {
      inputWidth: 0,
      link1: [
        {name: '研招网', url: 'https://yz.chsi.com.cn/'},
        {name: '学信网', url: 'https://www.chsi.com.cn/'},
        {name: '复旦大学研招网', url: 'https://gsao.fudan.edu.cn/'},
        {name: '复旦大学经济学院', url: 'https://econ.fudan.edu.cn/zyxw/tzgg.htm'}
      ],
      link2: [
        {name: '复旦大学经济学院', url: 'https://econ.fudan.edu.cn/zyxw/tzgg.htm'},
        {name: '复旦大学数学科学学院', url: 'https://math.fudan.edu.cn/30374/list.htm'},
        {name: '复旦大学大数据学院', url: 'https://sds.fudan.edu.cn/zytz/list.htm'}
      ],
      link3: [
        {name: '复旦大学精品课程', url: 'http://jpkc.fudan.edu.cn/'},
        {name: '中国知网', url: 'https://www.cnki.net/'},
        {name: '搜狗微信公众号', url: 'https://www.sogou.com/'},
        {name: 'B站视频观看', url: 'https://www.bilibili.com/'}
      ]
    };
  },
  methods: {
    resetTableHeight() {
      this.inputWidth = this.$refs.itemContent.offsetWidth;
    },
  },
}
</script>

<style lang="scss" scoped>
.bd {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 80px 0;

  .act {
    padding: 0 10px;
    flex: 2;

    &.flex2 {
      flex: 1;
    }

    ::v-deep .el-dropdown {
      width: 100%;
    }

    .ntion {
      width: 100%;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: rgba(245, 245, 245, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

    }

    .linkTitle {
      font-size: 24px;
      color: #333333;
      font-weight: 500;

      img {
        height: 32px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
</style>